// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// Export an initialization function that will be dynamically imported
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"eb6435baec524d3d2275500a68aa5200a578e3de"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";export const initSentry = async () => {
  // Only import the specific parts of Sentry that are needed
  // This allows for better tree shaking
  const { init, replayIntegration } = await import("@sentry/nextjs");

  // Create a custom client with minimal integrations
  init({
    dsn: "https://214c49c39630cd46b5aad44c833329b8@o4508695354212352.ingest.us.sentry.io/4508695356506112",

    // Selectively import only the integrations we need
    integrations: [
      replayIntegration({
        // Minimize replay size by excluding unnecessary elements
        maskAllText: true, 
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],

    // Add this to prevent data scrubbing
    beforeSend(event) {
      // Disable the default PII scrubbing
      return event;
    },
    
    // Set this to true if you want to include PII data
    sendDefaultPii: true,
    
    // Disable data scrubbing for IDs and other sensitive data
    denyUrls: [],
    allowUrls: ["*"],

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 0.1, // Reduced from 1 to lower overhead

    // Define how likely Replay events are sampled.
    // Reduced sample rates for better performance
    replaysSessionSampleRate: 0.05, // Reduced from 0.1
    replaysOnErrorSampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    enabled: process.env.NODE_ENV === 'production',
    
    // Additional optimizations
    normalizeDepth: 3,     // Limit serialization depth
    maxBreadcrumbs: 30,    // Limit breadcrumbs
  });
};

// Initialize Sentry asynchronously after page load
if (typeof window !== 'undefined') {
  // Use a more aggressive idle callback approach
  if (window.requestIdleCallback) {
    // Load Sentry during idle time with a 2-second timeout
    window.requestIdleCallback(() => {
      initSentry();
    }, { timeout: 2000 });
  } else {
    // Fallback to setTimeout with a longer delay to ensure page is fully loaded
    setTimeout(() => {
      initSentry();
    }, 2000);
  }
}
